import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import md5 from 'md5';
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            checkboxcookie: false,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            settingwheel: "",
            settingcheckin: "",
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            tabselect: 1,
            tabselectsec: 0,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            products_page_count: 1,
            loginstate: false,
            fullname: null,
            lname: null,
            link: "",
            alert: null,
            depositstate: 0,
            stateforget: 1,
            depositamount: 0,
            stateopterror: "",
            btnisActive: true,
            otpphone: "",
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            trueaccount: null,
            datas: null,
            otpphoneforget: "",
            affpercent: 0,
            counter: 300,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            cashoutlist: [],
            tblnextrank: [],
            tblrankall: [],
            maxwithdrawstatus: 0,
            tblprofile: [],
            prodetail: [],
            oldpassword: "",
            linkaff: "",
            webnamex: "",
            inviteSet: "",
            truegiftlink: "",
            proderedeem: [],
            qrcode: null,
            proderedeemlist: [],
            buttoncliam: false,
            limit: null,
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            cashoutSet: [],
            tblPopupMenu1: [],
            tblPopupMenu2: [],
            tblPopupMenu: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }


    checktab(e, index) {
        //console.log(index == 2 &&  this.state.qrcode != null);
        if (index == 2 && this.state.tabselectsec == 1) {

        }
        else {
            if (index == 2 && this.state.qrcode != null) {
                this.setState({
                    tabselect: 0,
                    tabselectsec: 1,
                });
            }
            else {
                this.setState({
                    tabselect: index,
                    tabselectsec: 0,
                });
            }
        }
    }
    onChangexdep = event => {
        ////console.log(event.target.value);
        if (event.target !== undefined) {

            this.setState({ depositamount: event.target.value });
        }
    };

    handleClickdeposit = async (e, price) => {
        this.setState({
            depositamount: price

        });
    }

    confirmdeposit = async (e, num) => {
        console.log(493, num)
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;


        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                if (num === 0) {
                    if (token != null) {
                        Username = decoded.message.playerid;
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture,
                                counter: 300,
                                tabselectsec: 1,
                                tabselect: 0
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }

        else if (num === 5) {
            document.getElementById("truemonney").click()
            //deposittruemoneyModal
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    redeemrank = async (e, index) => {
        e.preventDefault();
        //console.log(index);

        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            Swal.fire({
                icon: 'warning',
                title: 'คุณต้องการเคลม Rank หรือ ไม่?',
                showCancelButton: true,
                confirmButtonText: 'Save',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    await instance.post("/api/v1/userredeemrank", {
                        User_id: Username,
                        rankno: index.no,
                        System: Systems,
                        amount: index.claimamount
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            const datas = res.data.message;
                            this.calllisthistory(Username);
                            this.checklogin();
                            Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
                        }
                        else {
                            Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
                        }
                    });

                }
            })
        }

    }
    async rechecklogin(Username, token) {

        try {
            // console.log(4);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    var rankall = res.data.tblrankall;
                    const found = rankall.find(obj => {
                        return obj.checked === 1;
                    });
                    this.setState({
                        tblrankall: res.data.tblrankall,
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        linkaff: linkaff,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        tblprofile: res.data.message,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        linkaff: linkaff,
                        buttoncliam: (found === undefined) ? true : false,
                        tblnextrank: res.data.tblnextrank,
                        //  rankpic: (res.data.tblrank.image == null || res.data.tblrank.image == "") ? "https://betner.co/build/rank/1.jpeg" : (res.data.tblrank.picrack == null || res.data.tblrank.picrack == "") ? "https://betner.co/build/rank/" + res.data.tblrank.image : res.data.tblrank.picrack,
                        fullname: res.data.message.firstname,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    async componentDidMount() {
        // this.checklogin();

        try {
            var token = localStorage.getItem('auth_token');
            //  //////console.log(token);
            if (token != null) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                await this.rechecklogin(Username, token);
                this.setState({
                    userid: decoded.message.playerid,
                    username: decoded.message.playerid,
                    firstname: decoded.message.firstname,
                    lastname: decoded.message.lastname,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
            if (token != null) {
                var decoded = jwt_decode(token);
                let Username = decoded.message.playerid;
                instance.post("/api/v1/getqrcode", {
                    username: Username,
                    System: Systems,
                    amount: 50
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    //////console.log(res.data.picture)
                    this.setState({
                        mainacc: res.data.mainacc,
                    });
                });
            }
        }
        catch (error) {
            //////console.log();
        }
        try {

            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    // console.log( datas[7].value)
                    var min = res.data.tblcatagoryx[0].limitdeposit;
                    var max = res.data.tblcatagoryx[0].maxdeposit;
                    var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
                    var limit = { min, max }
                    this.setState({
                        maxwithdrawstatus: maxwithdrawstatus,
                        linelink: datas[1].value,
                        webnamex: datas[7].value,
                        System: res.data.tblcatagoryx[0].System,
                        cashoutSet: res.data.tblcashoutsetting[0],
                        inviteSet: res.data.tblinvitedsetting[0],
                        datas: datas,
                        limit: limit,
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        }
        catch (ex) {
            console.log(ex);
        }
    }
    render() {
        const pathname = this.state.pathname
        const { loginstate, userid, username, point, accnumber, firstname, tblnextrank, rankob, rankpoint, mainacc, lastname, balance, proderedeemlist, linkaff, tblprofile } = this.state
        var accnumberx;
        console.log(rankob);
        if (accnumber != null) {
            accnumberx = accnumber[0];
        }
        return (
            <>
                <div className="-games-index-page col-12">
                    <div className="mb-2 text-white-v1">
                        <div className="d-none d-lg-block">
                            <div>
                                <br />
                                <br />
                                <br />
                            </div>
                            {/**/}
                            {/**/}
                            <div>{/**/}</div>
                            {/**/}
                            {/**/}
                        </div>
                        {/**/}
                        <div className="padding-router-view">
                            <div className="container p-0 x-margin-top-v3">
                                <div className="row m-0 border-shadow-title">
                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                        <div className="text-center bg-title-web">
                                            <div>
                                                <div className="text-title text-white-v1 text-center">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="text-white-v1-deposit">
                                                            <a role="button" href='/'>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={25}
                                                                    height={25}
                                                                    fill="currentColor"
                                                                    className="bi bi-arrow-left-circle text-color-back"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <div className="text-white-v1-deposit">
                                                            <h5>ข้อมูลบัญชี</h5>
                                                        </div>
                                                        <div>{/**/}</div>
                                                    </div>
                                                    <hr className="mt-0" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="js-profile-account-modal -layout-account">
                                            <div className="x-account-profile -v2">
                                                <div
                                                    data-animatable="fadeInModal"
                                                    className="-profile-container animated fadeInModal"
                                                >
                                                    <div className="bg-profile-box">
                                                        <div className="d-flex justify-content-start align-self-center bg-dark-table p-2">
                                                            <div>
                                                                <div className="d-flex justify-content-start">
                                                                    <div className="align-self-center">
                                                                        <img
                                                                            width={"100px"}
                                                                            className="img-fluid -profile-image"
                                                                            src={"/build/rank/" + this.state.rankpic}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="p-2 align-self-center">
                                                                        <div className="username-profile">
                                                                            <b>
                                                                                {username}
                                                                            </b>
                                                                        </div>
                                                                        <div className="username-profile">
                                                                            <span className="badge text-bg-light">
                                                                                Bronze
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="x-admin-contact text-center m-0">
                                                            <a
                                                                role="button"
                                                                className="-link-change-password"
                                                                data-toggle="collapse"
                                                                data-target=".js-change-password-collapse"
                                                            >
                                                                <u>เปลี่ยนรหัสผ่าน</u>
                                                            </a>
                                                            <br />
                                                            <span className="x-text-with-link-component">
                                                                <label className="-text-message">พบปัญหา</label>
                                                                <a
                                                                    role="button"
                                                                    href={this.state.linelink}
                                                                    className="-link-message"
                                                                    target="_blank"
                                                                    rel="noopener"
                                                                >
                                                                    <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                                                </a>
                                                            </span>
                                                        </div>
                                                        <div className="animated animatedFadeInUp fadeInUp bg-profile-box-overview mb-2">
                                                            <div>
                                                                <div className="text-white-v1 text-center mx-auto my-auto">
                                                                    <div className="p-1 p-sm-1">
                                                                        <div className="flex justify-between mb-1 mx-auto">
                                                                            <div className="sub-content w-100 p-3 credit-bl-text">
                                                                                <div className="text-center">
                                                                                    <img
                                                                                        className="rounded-full w-10 mb-2 flex"
                                                                                        src={accnumber !== null ? accnumberx.img_path : ""}
                                                                                        style={{ width: 38 }}
                                                                                    />{" "}
                                                                                    &nbsp; {accnumber !== null ? accnumberx.fullname_th : ""}
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    {firstname} &nbsp; | &nbsp;  {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/**/}
                                                        </div>
                                                        <div className="pt-2 pb-2">

                                                            <div className="bg-profile-box-overview p-2 mb-2">
                                                                <div className="-group-trace-wrapper">

                                                                    <div className="x-profile-group-trace-condition ">
                                                                        <div className="-card-condition-wrapper">
                                                                            <div className="-card-condition-heading">
                                                                                <span className="-title">
                                                                                    เงื่อนไขขั้น {(tblnextrank === null) ? "" : tblnextrank.name}
                                                                                    {(tblnextrank === null) ? "" : <img
                                                                                        style={{ marginLeft: "10px" }}
                                                                                        width="50px"
                                                                                        className="mr-3"
                                                                                        src={(tblnextrank.image == null || tblnextrank.image == "") ? "https://betner.co/build/rank/1.jpeg" : (tblnextrank.picrack == null || tblnextrank.picrack == "") ? "https://betner.co/build/rank/" + tblnextrank.image : tblnextrank.picrack}
                                                                                        alt=""
                                                                                    />}
                                                                                </span>
                                                                            </div>
                                                                            <div className="-card-condition-description">
                                                                                ฝากอีก <span className="-highlight">{(rankob === null) ? "" : (rankob.highamount - rankpoint + 1).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> บาท
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text-right">
                                                                    <small id="max_deposit">{(rankob === null) ? 0 : ((rankpoint / rankob.highamount) * 100)} %</small>
                                                                </div>
                                                                <div className="pb-0">
                                                                    <div
                                                                        className="progress progress-lv"
                                                                        role="progressbar"
                                                                        aria-label="Example with label"
                                                                        aria-valuenow={(rankob === null) ? 0 : ((rankpoint / rankob.highamount) * 100)}
                                                                        style={{ height: "5px !important" }}
                                                                        aria-valuemin={0}
                                                                        aria-valuemax={(rankob === null) ? 0 : rankob.highamount - rankpoint}
                                                                    >
                                                                        <div
                                                                            className="progress-bar"
                                                                            style={{ width: (rankob === null) ? "" : ((rankpoint / rankob.highamount) * 100) + "%" }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between">

                                                                    <div className="align-self-center text-left pt-2">
                                                                        <div role="button">
                                                                            <small className="font-play-text">
                                                                                ยอดฝากปัจจุบัน
                                                                            </small>
                                                                            <div>
                                                                                <b>{(rankob === null) ? "" : rankob.lowamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="align-self-center text-right pt-2">
                                                                        <div role="button">
                                                                            <small className="font-play-text">
                                                                                คุณต้องทำยอดฝาก
                                                                            </small>
                                                                            <div>
                                                                                <b>{(rankob === null) ? "" : (rankob.highamount + 1).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}  บาท</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="m-0 color-hr" />
                                                            </div>
                                                            <table className="table bg-dark-table">
                                                                {this.state.tblrankall.map((listitem, i) => (
                                                                    <tr key={i} id={listitem.no} style={{ color: "white" }}>
                                                                        <td className="-description-body-wrapper">
                                                                            <img
                                                                                width={"100px"}
                                                                                className="img-fluid -profile-image"
                                                                                src={"/build/rank/" + listitem.image}
                                                                                alt=""
                                                                            />
                                                                            <div className="-title-wrapper">
                                                                                <p><span className="-title">{listitem.name}</span>        </p>
                                                                            </div>
                                                                            <div className="-state-wrapper">
                                                                                <span className="-text-description">{"เติม " + listitem.lowamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาทขึ้นไป {(listitem.no === 1) ? "" : "โบนัส " + listitem.claimamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " บาท"} </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="-transaction-body-wrapper">
                                                                            {
                                                                                (listitem.no === 1) ? "" :
                                                                                    (listitem.lowamount <= rankpoint && !listitem.checked) ? <div> <img
                                                                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                                                                        className="-ic"
                                                                                        width={"30px"}
                                                                                        alt=""
                                                                                    /><span className="-state-title" style={{ marginLeft: "10px" }}>สำเร็จ</span></div> :
                                                                                        (!listitem.checked) ? "ยังไม่ผ่านเงื่อนไข" : <a href={() => false} style={{ cursor: "pointer" }} className="text-white js-account-approve-aware btn -btn-deposit active" onClick={(e) => {
                                                                                            this.redeemrank(e, listitem)
                                                                                        }}><div className="f-7">Cliam</div></a>}
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default App;
