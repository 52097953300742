import React, { Component } from 'react'; //different
//import './style.css';
import NavMenu from './navmenu';
import Lowmenu from './lowmenu';
import Listmenu from './listmenu';
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect';
import Mainindex from './mainindex';
import Systems from "./SystemFunction";
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            slotlist: [],
            recentlist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            recentmenu: false,
            playerid: null,
            username: null,
            pathname: "",
            user_id: null,
            point: null,
            userid: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);

    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclick", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
        });

    }
    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }

    addDefaultSrc(ev, listitem) {
        const origin = listitem.split("http");
        //  console.log("img", origin.length)
        if (origin.length > 1) {
            return listitem;
        }
        else {
            return "https://ardmhzelxcmj.ocrazeckyunc.com" + listitem

        }

    }
    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }



    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    async rechecklogin(Username, token) {
        try {
            //console.log(1, Username, Systems, token);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-Main.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(181,res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        tblnextrank: res.data.tblnextrank,
                        tblprofile: res.data.message,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    //  console.log(res.data);
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }

    async componentDidMount() {
        this.setState({
            pathname: window.location.pathname.replace("/", "").trim()
        });
        const search = window.location.href;
        var test = search.split("aff=")[1]
        // console.log(test);
        if (test != undefined) {
            this.saveclick(test)
        }
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    System: res.data.tblcatagoryx[0].System
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        var Username;
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/tabgamelistpop", {
            state: "2",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "3",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        slotlist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "10",
            System: Systems,
            playerid: Username

        })
            .then((res) => {
                if (res.data.status === 200) {

                    // this.state.recentmenu
                    this.setState({
                        recentlist: res.data.message,
                        recentmenu: true
                    });
                    //////console.log(555, res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await this.checklogin();
    }

    render() {
        const pathname = this.state.pathname
        return (
            <>
                <div className="-games-provider-wrapper pl-0 pr-0 mx-auto -menu-index-page-bg col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 pl-0 pr-0">
                    <div className="-menu-index-page animated animatedFadeInUp in-left scroll-y-menu-left">
                        <div data-menu-sticky="js-sticky-widget" className="js-sticky-widget">
                            <div className="x-menu-provider js-tab-menu-provider">
                                <nav className="nav-menu" id="navbarCategory">
                                    <ul className="nav nav-pills js-menu-container -nav-menu-container mt-2">
                                        <li className="nav-item nav-img-bg w-100">
                                            <button className={"active-color-menu  nav-link -casino w-100 " + (pathname === "" ? "active" : "")}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = '/';
                                                }}
                                            >
                                                <img
                                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/9855c703-859c-4613-b4db-977aebad6c00/public"
                                                    alt="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/9855c703-859c-4613-b4db-977aebad6c00/public"
                                                    className="menu-icon-left-active img-fluid -ic-menu"
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">
                                                        <span>ยอดนิยม</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-trans">
                                                        <span>ยอดนิยม</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-mobile">
                                                        <span>ยอดนิยม</span>
                                                    </div>
                                                </div>
                                                {/**/}
                                            </button>
                                        </li>
                                        <li className="nav-item nav-img-bg w-100">
                                            <button className={"nav-link -casino w-100 " + (pathname === "casino" ? "active" : "")}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = '/casino';
                                                }}
                                            >
                                                <img
                                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/8195e083-f656-4314-8df1-331b04465c00/public"
                                                    alt="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/8195e083-f656-4314-8df1-331b04465c00/public"
                                                    className="menu-icon-left img-fluid -ic-menu"
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">
                                                        <span>คาสิโน</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-trans">
                                                        <span>คาสิโน</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-mobile">
                                                        <span>คาสิโน</span>
                                                    </div>
                                                </div>
                                                {/**/}
                                            </button>
                                        </li>
                                        <li className="nav-item nav-img-bg w-100">
                                            <button className={"nav-link -casino w-100 " + (pathname === "slot" ? "active" : "")} onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = '/slot';
                                            }}
                                            >
                                                <img
                                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/9855c703-859c-4613-b4db-977aebad6c00/public"
                                                    alt="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/9855c703-859c-4613-b4db-977aebad6c00/public"
                                                    className="menu-icon-left img-fluid -ic-menu"
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">
                                                        <span>สล็อต</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-trans">
                                                        <span>สล็อต</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-mobile">
                                                        <span>สล็อต</span>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                        <li className="nav-item nav-img-bg w-100">
                                            <button className={"nav-link -casino w-100 " + (pathname === "sport" ? "active" : "")} onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = '/sport';
                                            }}
                                            > <img
                                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/194f710e-cbf2-4d55-636b-d17e68a55200/public"
                                                    alt="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/194f710e-cbf2-4d55-636b-d17e68a55200/public"
                                                    className="menu-icon-left img-fluid -ic-menu"
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">
                                                        <span>กีฬา</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-trans">
                                                        <span>กีฬา</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-mobile">
                                                        <span>กีฬา</span>
                                                    </div>
                                                </div>
                                                {/**/}
                                            </button>
                                        </li>
                                        <li className="nav-item nav-img-bg w-100">
                                            <button className={"nav-link -casino w-100 " + (pathname === "fishing" ? "active" : "")} onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = '/fishing';
                                            }}
                                            >
                                                <img
                                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/aa0a4345-cbb5-4cef-754b-cf09f11e6400/public"
                                                    alt="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/aa0a4345-cbb5-4cef-754b-cf09f11e6400/public"
                                                    className="menu-icon-left img-fluid -ic-menu"
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">
                                                        <span>ยิงปลา</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-trans">
                                                        <span>ยิงปลา</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-mobile">
                                                        <span>ยิงปลา</span>
                                                    </div>
                                                </div>
                                                {/**/}
                                            </button>
                                        </li>
                                   
                                        <li className="nav-item nav-img-bg w-100">
                                            <button className={"nav-link -casino w-100 " + (pathname === "fishing" ? "active" : "")} onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = '/fishing';
                                            }}
                                            >
                                                <img
                                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/5b83a31b-93a0-4d6f-71c7-5b2b73bacb00/public"
                                                    alt="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/5b83a31b-93a0-4d6f-71c7-5b2b73bacb00/public"
                                                    className="menu-icon-left img-fluid -ic-menu"
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">
                                                        <span>เทรดดิ่ง</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-trans">
                                                        <span>เทรดดิ่ง</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-mobile">
                                                        <span>เทรดดิ่ง</span>
                                                    </div>
                                                </div>
                                                {/**/}
                                            </button>
                                        </li>
                                        <li className="nav-item nav-img-bg w-100">
                                            <button className={"nav-link -casino w-100 " + (pathname === "fishing" ? "active" : "")} onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = '/fishing';
                                            }}
                                            >
                                                <img
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2e8d1bac-60e6-476f-910b-dd3037eb3100/public"
                                                    alt="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2e8d1bac-60e6-476f-910b-dd3037eb3100/public"
                                                    className="menu-icon-left img-fluid -ic-menu"
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">
                                                        <span>หวย</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-trans">
                                                        <span>หวย</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-mobile">
                                                        <span>หวย</span>
                                                    </div>
                                                </div>
                                                {/**/}
                                            </button>
                                        </li>
                                        {/* <li className="nav-item nav-img-bg w-100">
                                            <button className="nav-link -casino w-100">
                                                <img
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7433d2df-f81a-4acc-19a2-12f9ebeebc00/public"
                                                    alt="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7433d2df-f81a-4acc-19a2-12f9ebeebc00/public"
                                                    className="menu-icon-left img-fluid -ic-menu"
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">
                                                        <span>หวย</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-trans">
                                                        <span>หวย</span>
                                                    </div>
                                                    <div className="-text-nav-menu -title-mobile">
                                                        <span>หวย</span>
                                                    </div>
                                                </div>
                                                
                                            </button>
                                        </li>  */}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
