import React, { Component } from 'react';
// import './App.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
//import './style.css';
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import Banner from './banner';
import MoblieMenu from "./mobilemenu";
import TextSlider from './textslide';
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categorylist: [],
      rewardlist: [],
      activePage: 1,
      formData: {}, // Contains login form data
      errors: {}, // Contains login field errors
      formSubmitted: false, // Indicates submit status of login form
      loading: false, // Indicates in progress state of login form
      pagecount: 1,
      playerid: null,
      user_id: null,
      point: null,
      products_page_count: 1,
      loginstate: false,
      firstname: null,
      lastname: null,
      alert: null,
    }
    this.handleClick = this.handleClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);

  }
  handlePageChange(pageNumber) {
    //////console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
    instance
      .get("/api/v1/rewardlist", {
        params: {
          Cat_ID: 0,
          page: pageNumber
        }
      })
      .then((res) => {
        this.setState({
          rewardlist: res.data.message.products,
          pagecount: res.data.message.pagecount,
          products_page_count: res.data.message.products_page_count
        });
      });
  }

  handleClick = async (e) => {
    this.setState({
      activePage: 1,
      isActive: true,
      activeTab: 0
    });
    instance.get("/api/v1/rewardlist", {
      params: {
        Cat_ID: e.target.id,
        page: 1
      }
    })
      .then((res) => {
        this.setState({
          rewardlist: res.data.message.products,
          pagecount: res.data.message.pagecount,
          products_page_count: res.data.message.products_page_count
        });
      });
    this.setState({
      isActive: false
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }



  redeem = (e) => {
    e.preventDefault();
    this.checklogin();
  }

  checklogin() {
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      this.setState({
        user_id: decoded.message.playerid,
        playerid: decoded.message.member_id,
        firstname: decoded.message.firstname,
        lastname: decoded.message.lastname,
        point: decoded.message.Point,
        loginstate: true,
      });
    }
    else {
      this.setState({
        loginstate: false,
      });
    }
  }

  async componentDidMount() {
    var token = localStorage.getItem('auth_token');
    const search = window.location.href;
    const params = new URLSearchParams(search);
    //this.checklogin();
    await instance.post("/api/v1/tabgamelist", {
      state: "8",
      System: Systems,
    })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            categorylist: res.data.message
          });
        }
        else {
        }
      });

  }
  render() {
    return (
      <>
    <div className="padding-router-view">
                    <div>
                        <main className="container p-0">
                            <div className="pt-1 pb-3 text-white-v1 w-scroll-height-casino">
                                <div className="x-category-provider js-game-scroll-container js-game-container animated animatedFadeInUp fadeInUp">
                                    <div className="-games-list-container container">
                                        <nav className="nav-menu" id="navbarProvider">
                                            <ul className="nav nav-pills row animated animatedFadeInUp fadeInUp">
                                                {/**/}
                                                {this.state.categorylist.map((listitem, i) => (
                                                    <li className="col-6 col-lg-2 col-md-3 nav-item -random-container -game-casino-macro-container p-1 pb-0 mb-2">
                                                        <a href={"/listgameall/casino/" + listitem.partner}>
                                                            <div
                                                                className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example bg-card-item"
                                                                data-status="-cannot-entry -untestable bg-card-item"
                                                            >
                                                                <div className="-inner-wrapper card-width-banner-games">
                                                                    <div>
                                                                    </div>
                                                                    <picture>
                                                                        <img
                                                                            role="button"
                                                                            className="w-100 hover-slot hoverTextCss"
                                                                            lazy="loaded"
                                                                            src={listitem.image}
                                                                        />
                                                                    </picture>
                                                                    <div className="-overlay d-none d-lg-block">
                                                                        <div className="-overlay-inner">
                                                                            <div className="-wrapper-container">
                                                                                <button
                                                                                    role="button"
                                                                                    className="-btn v-login js-account-approve-aware"
                                                                                    href={"/listgameall/casino/" + listitem.partner}
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width={20}
                                                                                        height={20}
                                                                                        fill="currentColor"
                                                                                        className="bi bi-play-circle-fill m-2"
                                                                                        viewBox="0 0 16 16"
                                                                                    >
                                                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                                                                                    </svg>
                                                                                    <span className="-text-btn">เข้าเล่น</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div>{/**/}</div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
      </>


    );
  }
}
export default App;
